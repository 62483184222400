<template>
  <div class="logo-wrapper">
    <Logo />
    <div class="actions">
      <div class="action">
        <router-link to="/features">
          Learn more
        </router-link>
        <div>
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
            />
          </svg>
        </div>
      </div>
      <div class="action">
        <a href="https://app.dev-precontrax.quintagroup.com/sign-up"
           target="_blank"
        >
          Create an organisation account
        </a>
        <div>
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="image-wrapper">
      <img src="@/assets/landing-page.jpg">
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo';
export default {
  name: "Home",
  components: {
    Logo,
  }
};
</script>